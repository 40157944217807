import React from 'react'

const ContactImg = () => {
  return (
    <div id='projects'  className="bg-[url('./img/contact.jpg')] bg-cover bg-center md:h-96 w-full h-60 md:basis-1/3">
      
    </div>
  )
}

export default ContactImg